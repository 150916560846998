<template>
  <div>
    <PageHeader title="Contacten zoeken">
      <span>
        Contacten zoeken
        <span v-if="filterMeta.name" class="tw-text-base">
          ({{ filterMeta.name }})
        </span>
      </span>

      <div slot="buttons" class="tw-font-normal tw-text-sm tw-flex tw-flex-wrap tw-justify-end tw-gap-1">
        <router-link
          :to="{ name: 'HmodhSearch' }"
          title="Zoeken op HMODH"
          class="action tw-px-2 tw-bg-success"
        >
          <i class="fas fa-search tw-mr-1" />
          Zoeken op HMODH
        </router-link>
        <button
          type="button"
          title="Nieuw contact aanmaken"
          class="action tw-px-2 tw-bg-success"
          @click="showContactCreateModal"
        >
          <i class="fas fa-user-plus tw-mr-1" /> Contact aanmaken
        </button>
      </div>
    </PageHeader>

    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <div class="tw-mb-8 tw-py-4 tw-px-6 tw-bg-white tw-rounded tw-shadow-lg">
        <FormulateForm
          :key="searchKey"
          v-model="filters"
          name="contactsSearch"
          debounce
          :class="['md:tw-grid tw-gap-x-6', enableTagSearch ? 'md:tw-grid-cols-3' : 'md:tw-grid-cols-2']"
        >
          <div>
            <FormulateInput
              type="radio"
              name="type"
              label="Type"
              value=""
              :options="{ '': 'Alle', P: 'Persoon', B: 'Bedrijf' }"
              input-class="tw-mt-3 tw-mb-2.5 tw-pb-px"
              outer-class="tw-my-4"
            />
            <FormulateInput
              v-if="filters.type === 'B'"
              type="text"
              name="company_name"
              label="Bedrijfsnaam"
              placeholder="Bedrijfsnaam"
            />
            <div v-else-if="filters.type === 'P'" class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
              <FormulateInput
                type="text"
                name="first_name"
                label="Voornaam"
                placeholder="Voornaam"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="text"
                name="last_name"
                label="Naam"
                placeholder="Naam"
                outer-class="tw-m-0"
              />
            </div>

            <FormulateInput
              v-else
              type="text"
              name="query"
              label="Naam"
              placeholder="Naam"
            />

            <FormulateInput
              type="text"
              name="email"
              label="E-mail"
              placeholder="E-mail"
            />
            <FormulateInput
              type="dial_code_tel"
              name="phone"
              label="Gsm- of telefoonnummer"
              validation="bail|optional|phone"
            />
            <FormulateInput
              type="text"
              name="vat"
              label="Ondernemingsnummer"
              placeholder="Ondernemingsnummer"
            />
            <FormulateInput
              type="checkbox"
              name="archived"
              label="Gearchiveerde contacten"
              placeholder="Selecteer ja of nee"
            />
          </div>

          <div>
            <FormulateInput
              type="text"
              name="street"
              label="Straat"
              placeholder="Straat"
            />

            <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
              <FormulateInput
                type="text"
                name="number"
                label="Huisnummer"
                placeholder="Huisnummer"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="text"
                name="box"
                label="Bus"
                placeholder="Bus"
                outer-class="tw-m-0"
              />
            </div>

            <FormulateInput
              type="autocomplete"
              auto-complete-type="city"
              name="city"
              label="Plaats"
              placeholder="Zoek op postcode of plaatsnaam"
            />
            <FormulateInput
              type="select"
              name="link_type"
              label="Relatietype"
              placeholder="Selecteer relatietype"
              :options="linkTypes"
            />
            <FormulateInput
              type="select"
              name="gdpr"
              label="GDPR-status"
              placeholder="Selecteer GDPR-status"
              :options="gdprOptions"
            />
          </div>

          <div v-if="enableTagSearch">
            <FormulateInput
              type="tagselect"
              track-by="name"
              name="tags_include"
              label="Tags inclusief"
              placeholder="Zoek naar een tag"
            >
              <template #label>
                <label
                  for="tags_include"
                  class="formulate-label tw-flex tw-justify-between"
                >
                  <span>Tags inclusief</span>
                  <button
                    type="button"
                    class="tw-text-xs"
                    @click="showTagsOverview(true)"
                  >
                    Overzicht <i class="fas fa-info-circle" />
                  </button>
                </label>
              </template>
            </FormulateInput>
            <FormulateInput
              type="tagselect"
              track-by="name"
              name="tags_exclude"
              label="Tags exclusief"
              placeholder="Zoek naar een tag"
            >
              <template #label>
                <label
                  for="tags_include"
                  class="formulate-label tw-flex tw-justify-between"
                >
                  <span>Tags exclusief</span>
                  <button
                    type="button"
                    class="tw-text-xs"
                    @click="showTagsOverview(false)"
                  >
                    Overzicht <i class="fas fa-info-circle" />
                  </button>
                </label>
              </template>
            </FormulateInput>
            <fieldset class="fieldset-split-range">
              <legend>Datumbereik tags</legend>
              <FormulateInput
                type="date"
                name="tag_date_from"
                placeholder="YYYY-MM-DD"
                validation="bail|optional|date:YYYY-MM-DD"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="date"
                name="tag_date_until"
                placeholder="YYYY-MM-DD"
                validation="bail|optional|date:YYYY-MM-DD"
                outer-class="tw-m-0"
              />
            </fieldset>

            <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4">
              <FormulateInput
                type="autocomplete"
                auto-complete-type="property"
                name="property"
                label="Pand"
                placeholder="Selecteer pand"
                outer-class="tw-m-0"
              />
              <FormulateInput
                type="autocomplete"
                auto-complete-type="project"
                name="project"
                label="Project"
                placeholder="Selecteer project"
                outer-class="tw-m-0"
              />
            </div>

            <table class="tw-w-full tw-table-auto tw-border-collapse">
              <thead>
                <tr>
                  <th>Filters</th>
                  <td class="tw-py-1 tw-text-right">
                    <button
                      type="button"
                      title="Nieuwe filter opslaan"
                      class="action tw-bg-success tw-text-xs"
                      @click="openFilterSaveModal"
                    >
                      <i class="fas fa-plus" />
                    </button>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="filter in savedFilters"
                  :key="filter.id"
                  :class="[
                    'tw-border-b last:tw-border-none tw-border-gray-cc',
                    { 'tw-bg-success tw-bg-opacity-5': filterMeta.id == filter.id }
                  ]"
                >
                  <td class="tw-py-1">
                    <router-link :to="getUrlForSavedFilter(filter)" class="tw-block tw-font-semibold">
                      {{ filter.name }}
                    </router-link>
                  </td>
                  <td class="tw-py-1 tw-text-right">
                    <button
                      v-if="filterMeta.id == filter.id"
                      type="button"
                      title="Filter bijwerken"
                      class="action tw-bg-success tw-text-xs"
                      @click="updateSavedFilter(filter)"
                    >
                      <i class="fas fa-save" />
                    </button>
                    <button
                      type="button"
                      title="Verwijderen"
                      class="action tw-bg-danger tw-text-xs"
                      @click="deleteSavedFilter(filter)"
                    >
                      <i class="fas fa-trash" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="tw-my-4 tw-col-span-3 tw-flex tw-flex-wrap tw-gap-x-6 tw-gap-y-4 tw-text-base tw-text-center tw-justify-between">
            <div class="tw-flex tw-gap-x-6 tw-gap-y-4 tw-flex-wrap tw-flex-grow sm:tw-flex-grow-0">
              <router-link
                :to="{ query: { filters: JSON.stringify(filters) } }"
                class="tw-px-6 tw-py-2 !tw-text-white !tw-no-underline tw-bg-success hover:tw-bg-darken-success tw-rounded-md tw-flex-grow"
              >
                <i class="tw-mr-1 far fa-search" /> Zoeken
              </router-link>
              <button
                type="button"
                class="tw-px-6 tw-py-2 tw-bg-danger hover:tw-bg-darken-danger tw-rounded-md tw-text-white tw-flex-grow"
                @click="resetFilters"
              >
                <i class="tw-mr-1 far fa-times" /> Filters wissen
              </button>
            </div>
          </div>
        </FormulateForm>
      </div>

      <DataTable
        v-show="showDataTable"
        v-model="selectedRecords"
        :loading="loading"
        :headers="headers"
        :can-select="true"
        :infinite-scroll="true"
        :all-record-ids="allContactIds"
        :select-all-by-query="true"
        v-bind="contacts"
        @allSelected="selectAll"
        @infiniteLoad="infiniteLoad"
        @changedOrder="queryContacts({ ...$event.params, ...filters })"
      >
        <template #toolbar>
          <div class="tw-mb-2 tw-flex tw-justify-end">
            <button
              type="button"
              title="Tag toewijzen"
              :disabled="!(selectedAllByQuery || totalSelections.length)"
              class="action tw-bg-success"
              @click="showAssignTagModal"
            >
              <i class="fas fa-tags" /> Tag
            </button>
            <div class="btn-group">
              <button
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
                :disabled="!totalSelections.length || totalSelections.length > 50"
                class="action tw-bg-success dropdown-toggle"
              >
                <i class="fas fa-envelope" /> Mailen <i class="caret" />
              </button>
              <ul
                role="menu"
                aria-orientation="vertical"
                class="dropdown-menu pull-right"
              >
                <li>
                  <a title="Mailen als kandidaat" @click="mailContacts('candidate')">
                    Mailen als kandidaat
                  </a>
                </li>
                <li>
                  <a title="Mailen als BCC" @click="mailContacts('bcc')">
                    Mailen als BCC
                  </a>
                </li>
              </ul>
            </div>
            <button
              v-if="collaborator && collaborator.hasPerm('EXPORT_CONTACT_GROUP')"
              type="button"
              title="Exporteren"
              :disabled="!(selectedAllByQuery || totalSelections.length)"
              class="action tw-bg-success"
              @click="openExportModal"
            >
              <i class="fas fa-external-link" /> Exporteren
            </button>
            <button
              type="button"
              :title="mergeContactError || 'Contacten samenvoegen'"
              :disabled="mergeContactError"
              class="action tw-bg-success tw-mr-0"
              @click="showContactMergeModal"
            >
              <i class="fas fa-compress-alt" /> Contacten samenvoegen
            </button>
          </div>
        </template>
        <template #item-type="{ item }">
          <i v-if="item.type === 'P'" class="fas fa-user" />
          <button
            v-else
            type="button"
            class="link tw-font-semibold tw-text-left"
            @click="toggleCompanyContacts(item.id)"
          >
            <i class="fas fa-users tw-mr-2" />
            <i :class="['fas', displayCompanyContacts[item.id] ? 'fa-chevron-up' : 'fa-chevron-down']" />
          </button>
        </template>
        <template #item-phone="{ item }">
          <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
        </template>
        <template #item-mobile="{ item }">
          <a :href="`tel:${item.mobile}`">{{ item.mobile }}</a>
        </template>
        <template #item-created_on="{ item }">
          {{ item.created_on | date-day }}
        </template>
        <template #item-updated_on="{ item }">
          {{ item.updated_on | date-day }}
        </template>

        <template #item-score="{ item }">
          <ContactScore
            v-if="item.score"
            :score="item.score"
            :contact-id="item.id"
          />
          <span v-else>-</span>
        </template>

        <template #record-extend="{ record }">
          <template v-if="record.type === 'B'">
            <tr
              v-for="(contact, index) in record.linked_contacts"
              :key="`${record.id}_child_${contact.id}_${index}`"
              v-show="displayCompanyContacts[record.id]"
              class="tw-border-b tw-border-l tw-bg-gray-50"
            >
              <td class="tw-font-semibold">
                <input
                  type="checkbox"
                  v-model="selectedLinkedRecords[record.id]"
                  :id="`contact_person_${contact.id}`"
                  :name="`contact_person_${contact.id}`"
                  :value="contact.id"
                />
              </td>
              <td>{{ contact.link_type || '-' }}</td>
              <td><ContactName :object="contact" /></td>
              <td><ContactCity :object="contact" /></td>
              <td><ContactEmail :object="contact" /></td>
              <td><a :href="`tel:${contact.phone}`">{{ contact.phone }}</a></td>
              <td><a :href="`tel:${contact.mobile}`">{{ contact.mobile }}</a></td>
              <td>{{ contact.created_on | date-day }}</td>
              <td>{{ contact.updated_on | date-day }}</td>
              <td><ContactNotes :object="contact" /></td>
              <td><ContactGDPR :object="contact" /></td>
            </tr>
          </template>
        </template>
      </DataTable>
    </div>

    <ContactMergeModalV2
      ref="contactMergeModalV2"
    />
    <ContactCreateModal ref="contactCreateModal" />
    <AssignTagModal
      ref="assignTagModal"
      :search-params="params"
      :contact-ids="totalSelections"
      :contact-count="contacts.count"
    />
    <BaseModal ref="saveFiltersModal" title="Filter opslaan" max-width="tw-max-w-sm" @hide="filterSaveForm = {}">
      <FormulateForm
        #default="{ isLoading }"
        v-model="filterSaveForm"
        name="filterSaveForm"
        @submit="saveFilters"
      >
        <FormulateInput
          type="text"
          name="name"
          label="Filternaam"
          placeholder="Filternaam"
          validation="required:trim"
        />
        <FormulateErrors />
        <FormulateInput type="submit" :disabled="isLoading" :outer-class="['tw-float-right']">
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Opslaan
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
    <BaseModal ref="exportModal" title="Contacten exporteren" max-width="tw-max-w-sm" @hide="exportForm = {}">
      <FormulateForm
        #default="{ isLoading }"
        v-model="exportForm"
        name="exportForm"
        @submit="exportContacts"
      >
        Er worden <span class="tw-font-semibold">
          {{ totalSelections.length || contacts.count }} {{ (totalSelections.length || contacts.count) > 1 ? 'contacten' : 'contact' }}
        </span> geëxporteerd.
        <FormulateInput
          type="text"
          name="reason"
          label="Reden"
          placeholder="Reden"
          validation="required:trim"
        />

        <FormulateErrors />

        <FormulateInput type="submit" :disabled="isLoading" :outer-class="['tw-float-right']">
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-external-link'
            ]"
          />
          Exporteren
        </FormulateInput>
      </FormulateForm>
    </BaseModal>
    <ContactTagsOverview
      ref="overview"
      :exclude-automated-tags="false"
      @tagSelected="addTagToSelection"
    />
  </div>
</template>

<script>
import merge from 'lodash/merge'
import difference from 'lodash/difference'
import EventBus from '@/components/iam/bus'
import { poll } from '@/utils/helpers'

import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable'
import ContactMergeModalV2 from '@/components/contacts/ContactMergeModalV2'

import ContactCity from '@/components/contacts/contact_render_components/ContactCity'
import ContactEmail from '@/components/contacts/contact_render_components/ContactEmail'
import ContactGDPR from '@/components/contacts/contact_render_components/ContactGDPR'
import ContactName from '@/components/contacts/contact_render_components/ContactName'
import ContactNotes from '@/components/contacts/contact_render_components/ContactNotes'

import AssignTagModal from '@/components/contacts/AssignTagModal'
import ContactCreateModal from '@/components/contacts/ContactCreateModal'
import ContactTagsOverview from '@/components/contacts/ContactTagsOverview'
import ContactScore from '@/components/contacts/ContactScore.vue'

import { mapGetters, mapState } from 'vuex'
import { successModal, errorModal } from '@/modalMessages'
import {
  getContacts,
  getContactLinkTypes,
  getSavedContactFilters,
  saveContactFilters,
  updateContactFilters,
  deleteContactFilter,
  getContactsByIds,
  exportContactsAsCsv,
  pollExportJob
} from '@/services/contacts'

import {
  createLog
} from '@/services/apiService'

export default {
  name: 'ContactSearch',
  components: {
    PageHeader,
    DataTable,
    ContactName,
    ContactCity,
    ContactEmail,
    ContactNotes,
    ContactGDPR,
    AssignTagModal,
    ContactMergeModalV2,
    ContactCreateModal,
    ContactScore,
    ContactTagsOverview
  },
  data () {
    return {
      searchKey: 'init',
      filters: {
        page_size: 50,
        name_only: 1 // Required by BE to limit the "filters" field in params to search over first_name, last_name, and company_name
      },
      loading: false,
      // The object contacts includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      contacts: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      selectedRecords: [],
      selectedLinkedRecords: {},
      allContactIds: [],
      allLinkedIds: [],
      displayCompanyContacts: {},
      linkTypes: [],
      showDataTable: false,
      filterSaveForm: {},
      savedFilters: [],
      filterMeta: {},
      exportForm: {},
      params: {},
      selectedAllByQuery: false
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value) {
        // Do not remove applied filter meta if it exists and do not reset the form when search button is pressed
        this.clearValues(false, false)

        if (!value.filters) {
          this.showDataTable = false
          return false
        }

        this.showDataTable = true
        const parsedFilters = JSON.parse(value.filters)
        const mergedFilters = merge(this.filters, parsedFilters)
        this.filters = Object.assign({}, this.filters, mergedFilters)

        if (value.filter_meta) this.filterMeta = JSON.parse(value.filter_meta)
        this.queryContacts()
      }
    },
    selectedRecords (newArr, oldArr) {
      if (!Array.isArray(newArr)) return

      const addedContactId = difference(newArr, oldArr)[0]
      const removedContactId = difference(oldArr, newArr)[0]

      if (!this.allLinkedIds[addedContactId || removedContactId]) return false // Return if the linked contact ids do not exist for selection
      if (addedContactId) this.$set(this.selectedLinkedRecords, addedContactId, this.allLinkedIds[addedContactId])
      else if (removedContactId) this.$set(this.selectedLinkedRecords, removedContactId, [])
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['collaborator']),

    headers () {
      return [
        { text: 'Type', value: 'type', orderable: true },
        { text: 'Naam', value: 'rname', renderComponent: ContactName, orderable: true },
        { text: 'Woonplaats', value: 'residence', renderComponent: ContactCity, orderable: true, orderByKey: 'city_name' },
        { text: 'E-mail', value: 'email', renderComponent: ContactEmail, orderable: true },
        { text: 'Telefoon', value: 'phone', orderable: true },
        { text: 'Gsm', value: 'mobile', orderable: true },
        { text: 'Aanmaakdatum', value: 'created_on', orderable: true },
        { text: 'Laatste wijziging', value: 'updated_on', orderable: true },
        { text: 'Commentaar', value: 'notes', renderComponent: ContactNotes },
        { text: 'Score', value: 'score', orderable: true },
        { text: 'GDPR', value: 'gdpr', renderComponent: ContactGDPR, orderable: true }
      ]
    },
    mergeContactError () {
      const rangeValid = Array.isArray(this.selectedRecords) && this.selectedRecords.length <= 2
      if (!rangeValid) return 'Enkel mogelijk voor minder dan 3 contacten'
      if (this.selectedRecords.length === 1 || !this.selectedRecords.length) return false
      const typesForSelectedContacts = this.contacts.results.filter(c => { return this.selectedRecords.includes(c.id) }).map(c => c.type)
      // No merging is possible if contact types include both 'P' (person) and 'B' (business)
      const uniqueTypes = [...new Set(typesForSelectedContacts)]
      if (uniqueTypes.length > 1) return 'Samenvoegen is niet mogelijk voor verschillende contacttypes'
      return false
    },
    totalSelections () {
      if (!Array.isArray(this.selectedRecords)) return []
      const selectedLinkedRecords = Object.values(this.selectedLinkedRecords).flat()
      return [...this.selectedRecords, ...selectedLinkedRecords]
    },
    enableTagSearch () {
      return true // Feature toggle to disable the functionality if need be (due to performance reasons)
    },
    gdprOptions () {
      return [
        { value: 0, label: 'Groen' },
        { value: 2, label: 'Oranje' },
        { value: 1, label: 'Rood' }
      ]
    }
  },
  created () {
    this.init()
  },
  mounted () {
    if (this.$route.query?.openCreate === '1') {
      this.showContactCreateModal()
    }
  },
  methods: {
    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    showAssignTagModal () {
      this.$refs.assignTagModal.show()
    },
    async showContactMergeModal () {
      const primaryContactId = this.selectedRecords.length ? this.selectedRecords[0] : null
      const mergedContactId = this.selectedRecords.length ? this.selectedRecords[1] : null
      const response = this.$refs.contactMergeModalV2.show(primaryContactId, mergedContactId)
      return response
    },
    selectAll (allSelected) {
      this.selectedAllByQuery = false

      if (!Array.isArray(this.selectedRecords)) {
        // When selecting all items, with the prop select-all-by-query="true" passed to the DataTable,
        // this.selectedRecords returns the params added by the DataTable.
        this.params = { ...this.parseParams(this.filters), ...this.selectedRecords }
        this.selectedAllByQuery = true
        return false
      }

      if (allSelected) this.selectedLinkedRecords = { ...this.allLinkedIds }
      else {
        Object.keys(this.selectedLinkedRecords).forEach(contactId => {
          this.$set(this.selectedLinkedRecords, contactId, [])
        })
      }
    },
    resetSelections () {
      this.selectedRecords = []
      this.selectedLinkedRecords = {}
    },
    toggleCompanyContacts (itemId) {
      this.$set(this.displayCompanyContacts, itemId, !this.displayCompanyContacts[itemId])
    },
    openFilterSaveModal () {
      this.$refs.saveFiltersModal.show()
    },
    hideFilterSaveModal () {
      this.$refs.saveFiltersModal.hide()
    },
    openExportModal () {
      this.$refs.exportModal.show()
    },
    hideExportModal () {
      this.$refs.exportModal.hide()
    },
    parseParams (payload) {
      const params = { ...payload }
      params.city = params.city?.id ? params.city.id : ''
      params.tags_include = params.tags_include?.map(tag => tag.id) || []
      params.tags_exclude = params.tags_exclude?.map(tag => tag.id) || []
      params.property = params.property?.id ? params.property.id : ''
      params.project = params.project?.id ? params.project.id : ''
      return params
    },
    getUrlForSavedFilter (filter) {
      const { id, name, search_params } = filter
      const filters = JSON.stringify(search_params)
      const filter_meta = JSON.stringify({ id, name })
      return { query: { filter_meta, filters } }
    },
    clearValues (clearMeta = true, resetForm = true) {
      if (resetForm) this.searchKey = Math.random() // Reset form and it's values
      this.filters = { name_only: 1, page_size: 50 } // set filters to default value
      if (clearMeta) this.filterMeta = {}
      this.contacts = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
      this.allContactIds = []
      this.allLinkedIds = []
      this.resetSelections()
    },
    redirectToMergedContact (contactId) {
      this.$router.push({ name: 'ContactDetails', params: { id: contactId } })
    },

    init () {
      return Promise.all([this.loadSavedFilters(), this.loadLinkTypes()])
    },
    async loadContacts (payload) {
      try {
        const response = await getContacts(payload)
        const contacts = response.data
        // Set empty array to collect linked_contact selections, if there is no array// Set empty array to collect linked_contact selections, if there is no array
        contacts.results.forEach(contact => {
          if (contact.type === 'B' && contact.linked_contacts?.length) {
            if (!this.selectedLinkedRecords[contact.id]?.length) this.$set(this.selectedLinkedRecords, contact.id, [])
          }
        })
        return contacts
      } catch (error) {
        console.error(error)
        errorModal('Kan contacten niet laden, probeer het opnieuw.')
      }
    },
    async loadLinkTypes () {
      const response = await getContactLinkTypes()
      const linkTypes = response.data?.results || []
      this.linkTypes = linkTypes.map(({ name, id }) => {
        return { label: name, value: id }
      })
      return response
    },

    async queryContacts (data = this.filters) {
      // To show loading spinner of DataTable, not the infinite loading
      this.loading = true
      this.resetSelections()
      const params = this.parseParams(data)
      const contacts = await this.loadContacts({ params })
      this.contacts = contacts
      this.allContactIds = []
      // Load ids only when the result count is less than 50
      if (contacts.count < 50) await this.loadContactIds({ params })
      this.loading = false
      return contacts
    },
    async resetFilters () {
      this.clearValues()
      this.$router.push({ query: {} }).catch(() => {})
      successModal('Alle filters gewist.')
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.contacts.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const contacts = await this.loadContacts({ url: next })
        const results = [...this.contacts.results, ...contacts.results]
        this.contacts = { ...contacts, results }
        $infinite.loaded()
        return contacts
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async loadContactIds ({ params = {} }) {
      try {
        const [contactIds, linkedContactIds] = await Promise.all([
          getContacts({ params: { ...params, ids_only: 1 } }),
          getContacts({ params: { ...params, linked_contact_ids_only: 1, type: 'B' } })
        ])
        this.allContactIds = contactIds.data
        this.allLinkedIds = linkedContactIds.data
        return [contactIds, linkedContactIds]
      } catch (error) {
        errorModal('Er is iets misgegaan bij het zoeken. Selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },

    async mergeContacts () {
      /* Load full contacts, not display contacts */
      const response = await this.$refs.mergeModal.init([...this.selectedRecords])
      return response
    },

    async mailContacts (target) {
      const params = { contact_ids: [...this.totalSelections] }
      const response = await getContactsByIds(params)
      const logPayload = {
        action_id: 19, // mailing_contact_search
        meta: { count: this.totalSelections.length }
      }
      await createLog(logPayload)
      const contacts = response.data.map(contact => {
        return {
          contact,
          type: target === 'candidate' ? 'candidate' : 'bcc'
        }
      })
      EventBus.$emit('mailclient-prefill-and-show', { contacts })
    },

    async loadSavedFilters () {
      const response = await getSavedContactFilters({ page_size: 1000 })
      this.savedFilters = response.data?.results || []
      return response
    },
    async saveFilters (data) {
      try {
        const search_params = this.parseParams(this.filters)
        const response = await saveContactFilters({ search_params, ...data })
        this.savedFilters.unshift(response.data)
        this.hideFilterSaveModal()
        this.filterSaveForm = {}
        successModal('Contactfilters opgeslagen')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'filterSaveForm')
      }
    },
    async updateSavedFilter (filter) {
      try {
        const search_params = this.parseParams(this.filters)
        const response = await updateContactFilters(filter.id, { name: filter.name, search_params })
        filter.search_params = search_params
        successModal('Contactfilter bijgewerkt')
        return response
      } catch (error) {
        errorModal(`Kan contactfilter ${filter.name} niet updaten.`, true)
        console.error(error)
      }
    },
    async deleteSavedFilter (filter) {
      try {
        if (this.filterMeta?.id === filter.id) this.clearValues()
        this.savedFilters = this.savedFilters.filter(item => item.id !== filter.id)
        const response = await deleteContactFilter(filter.id)
        successModal('Contactfilter verwijderd')
        return response
      } catch (error) {
        errorModal(`Kan ${filter.name} niet verwijderen`, true)
        console.error(error)
      }
    },

    async exportContacts (data) {
      try {
        const contact_ids = this.totalSelections
        const { ordering, archived } = this.params
        const search_params = this.parseParams(this.filters)
        const payload = { contact_ids, ordering, archived, search_params, ...data }

        const response = await exportContactsAsCsv(payload)
        const { job_id } = response.data
        const pollResult = await poll(job_id, pollExportJob)

        const win = window.open(pollResult?.url, '_blank')
        if (win) {
          win.focus()
          this.hideExportModal()
          successModal(`Succesvol ${this.totalSelections.length || this.contacts.count} records geëxporteerd.`)
        } else errorModal('Sta pop-ups toe om het document te zien.')

        return pollResult
      } catch (error) {
        this.$formulate.handle(error, 'exportForm')
        errorModal('Export werd afgebroken, probeer het opnieuw.')
        console.error(error)
      }
    },

    showTagsOverview (include) {
      this.$refs.overview.setInclude(include)
      this.$refs.overview.show()
    },

    addUniqueTagToTagsArray (tags, newTag) {
      if (!tags) return [newTag]
      const exists = tags.some(tag => tag.id === newTag.id)
      if (exists) return tags
      return [...tags, newTag]
    },

    addTagToSelection (eventData) {
      if (eventData.include) {
        const tags_include = this.addUniqueTagToTagsArray(this.filters.tags_include, eventData.tag)
        this.$set(this.filters, 'tags_include', tags_include)
      } else {
        const tags_exclude = this.addUniqueTagToTagsArray(this.filters.tags_exclude, eventData.tag)
        this.$set(this.filters, 'tags_exclude', tags_exclude)
      }
    }
  }
}
</script>

<style scoped>
/* To make the type radio options inline */
/deep/ div[role="group"] {
  @apply tw-flex tw-flex-wrap tw-gap-x-4;
}
</style>
